import { Filters, getVehicles } from '@finn/b2c-cp/deprecated/fleet-api';
import { GenericVehicleDetails, ProductCard } from '@finn/ua-vehicle';
import { CTA } from '@finn/ui-components';
import { CTAData } from '@finn/ui-cosmic';
import { cn, parseToHtml, useCurrentLocale } from '@finn/ui-utils';
import React, { useEffect, useMemo, useState } from 'react';

// todo: slider has a new design... consider importing from @finn/ui-modules to get the new design
import Slider from './Slider';

interface IProps {
  title?: string;
  filter?: Filters;
  cta?: CTAData;
  baseURL?: string;
  urlParams?: string;
  isForIframe?: boolean;
  hidePrice?: boolean;
  forceB2BPrice?: boolean;
  withinContainer?: boolean;
}

const ProductSlider: React.FunctionComponent<IProps> = ({
  title,
  filter,
  cta,
  baseURL,
  urlParams,
  isForIframe = false,
  hidePrice = false,
  forceB2BPrice = false,
  withinContainer,
}) => {
  const { locale } = useCurrentLocale();
  const [vehicles, setVehicles] = useState<GenericVehicleDetails[]>([]);

  useEffect(() => {
    const fetchVehicles = async () => {
      const { results } = await getVehicles({
        filter: { ...filter },
        locale,
      });
      setVehicles(results);
    };
    fetchVehicles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  const ctaMetadata = useMemo(
    () => ({
      ...cta?.metadata,
      href:
        cta?.metadata?.href && baseURL
          ? `${baseURL}${cta.metadata.href}`
          : cta?.metadata?.href,
    }),
    [cta?.metadata, baseURL]
  );

  return (
    <>
      {title && (
        <div className="container">
          <h2 className="web-t2-semibold mb-14">{parseToHtml(title)}</h2>
        </div>
      )}
      <Slider isForIframe={isForIframe} withinContainer={withinContainer}>
        {vehicles.map((vehicle, idx) => (
          <div key={vehicle.id} className="w-72 sm:w-96">
            <ProductCard
              vehicle={vehicle}
              parentTitle={title}
              shouldShowPlaceholder={false}
              position={idx + 1}
              baseURL={baseURL}
              urlParams={urlParams}
              isForIframe={isForIframe}
              hidePrice={hidePrice}
              forceB2BPrice={forceB2BPrice}
            />
          </div>
        ))}
      </Slider>
      {cta && (
        <div className="container">
          <div className={cn('sm:mt-15 mb-4 mt-12')}>
            <CTA data={ctaMetadata} />
          </div>
        </div>
      )}
    </>
  );
};

export default ProductSlider;
