import { Deal } from '@finn/platform-modules';
import dayjs from 'dayjs';

export const daysFromNowToDate = (date: string) => {
  if (!date) {
    // achieves backward compatible with the previous implementation
    // some usages are changing for the worse if "days between today and today" simply returns zero
    return NaN;
  }

  let today = dayjs();
  let futureDate = dayjs(date);

  // Set the time for both dates to midnight
  futureDate = futureDate.startOf('day');
  today = today.startOf('day');

  const difference = futureDate.diff(today, 'days');

  const daysLeft = Math.ceil(difference);

  return daysLeft;
};

const apiToDealParamMap = {
  customer_id: 'contact_id',
  subscription_id: 'id',
  lead_id: 'id',
};

export const parametrizeUrl = ({
  url,
  deal,
  dealId,
}: {
  url: string;
  deal: Deal;
  dealId?: string;
}) => {
  const pattern = /\{.*?\}/g;

  // TODO remove after cosmic updated to new link
  if (url === '/api/deal/{subscription_id}/mileage-report') {
    url = '/portal/api/bff/deals/{subscription_id}/mileage-report';
  }

  const urlMatchesArr = url?.match?.(pattern);
  urlMatchesArr?.forEach((match) => {
    const key = match?.replace?.('{', '').replace('}', '');
    const param = apiToDealParamMap[key];
    if (param === undefined) {
      throw new Error(`Unsupported parameter in URL ${match}`);
    }

    // to ensure that we have fallback if we use url befoe deal is loaded
    const value = param === 'id' ? deal?.[param] || dealId : deal?.[param];
    // if param is id, but value is undefined, it means that we do not yet
    // have deal id, it can happen because of nextjs router being client side
    // or deal request being in progress, but without it url does not make sense
    // and we should not fetch data
    if (value === undefined && param === 'id') {
      url = null;
    } else {
      url = url?.replace?.(match, value);
    }
  });

  return url;
};

export enum EditFormStatuses {
  FORM_EDITABLE = 'FORM_EDITABLE',
  ADDRESS_EDITABLE = 'ADDRESS_EDITABLE',
  FORM_NOT_EDITABLE = 'FORM_NOT_EDITABLE',
}

export function getEditDeliveryFormStatus(dates: Date[]): EditFormStatuses {
  switch (dates?.length) {
    case 0:
      return EditFormStatuses.FORM_NOT_EDITABLE;
    case 1:
      return EditFormStatuses.ADDRESS_EDITABLE;
    default:
      return EditFormStatuses.FORM_EDITABLE;
  }
}

export const convertToDates = (availabilities: string[]): Date[] => {
  const convertedDates = availabilities?.map(
    (availability) => new Date(availability)
  );

  return convertedDates;
};

export const transformVehicleDetailsData = ({
  data,
  handoverDateCKO,
}: {
  data?: Deal;
  handoverDateCKO?: string;
}) => {
  if (!data) {
    return undefined;
  }

  const hasHandoverHappened = Boolean(data?.actual_handover_date);
  const hasHandoverDateCKO = Boolean(handoverDateCKO);

  const shouldShowSeparateDates = data?.has_auto_renewal && hasHandoverHappened;
  const shouldShowEndDate = !data?.has_auto_renewal && hasHandoverHappened;

  return {
    ...data,
    probable_handover_date:
      hasHandoverHappened || hasHandoverDateCKO
        ? undefined
        : data?.handover_appointment_date,
    lead_handover_date: hasHandoverHappened ? undefined : handoverDateCKO,
    minimum_commitment_end_date: shouldShowSeparateDates
      ? data?.minimum_commitment_end_date
      : undefined,
    auto_renewal_end_date: shouldShowSeparateDates
      ? data?.auto_renewal_end_date
      : undefined,
    // the actual data.end_date is getting deprecated,
    // we are using this field to assign minimum_commitment_end_date into it when auto_renewal_end_date is missing
    end_date: shouldShowEndDate ? data?.minimum_commitment_end_date : undefined,
  };
};
